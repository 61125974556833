package index

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.attributes.ATarget
import org.jetbrains.compose.web.attributes.target
import org.jetbrains.compose.web.dom.*

@Composable
fun HeaderNav() {
    Header {
        Div(attrs = {
            classes("header-area", "header-transparent")
        }) {
            Div(attrs = {
                classes("main-header", "header-sticky")
            }) {
                Div(attrs = {
                    classes("container-fluid")
                }) {
                    Div(attrs = {
                        classes("row", "align-items-center")
                    }) {
                        Logo()
                        Menu()
                        MenuMobile()
                    }
                }
            }
        }
    }
}

@Composable
private fun Logo() {
    Div(attrs = {
        classes("col-xl-2", "col-lg-2", "col-md-1")
    }) {
        Div(attrs = {
            classes("logo")
        }) {
            A {
                Img(src = "assets/img/logo/logo.png")
            }
        }
    }
}

@Composable
private fun Menu() {
    Div(attrs = {
        classes("col-xl-10", "col-lg-10", "col-md-10")
    }) {
        Div(attrs = {
            classes("menu-main", "d-flex", "align-items-center", "justify-content-end")
        }) {
            Div(attrs = {
                classes("main-menu", "f-right", "d-none", "d-lg-block")
            }) {
                Navigation()
            }
            WhatsAppButton()
        }
    }
}

@Composable
private fun Navigation() {
    Nav {
        Ul(attrs = {
            id("navigation")
        }) {
            Li {
                A(href = "#about") { Text("Acerca de") }
            }
            Li {
                A(href = "#gallery") { Text("Galeria") }
            }
            Li {
                A(href = "#schedule") { Text("Agenda") }
            }
            Li {
                A(href = "#contact") { Text("Contacto") }
            }
        }
    }
}

@Composable
private fun WhatsAppButton() {
    Div(attrs = {
        classes("header-right-btn", "f-right", "d-none", "d-lg-block", "ml-30")
    }) {
        A(
            href = "https://wa.me/573193769897",
            attrs = {
                classes("btn", "header-btn")
                target(ATarget.Blank)
            }
        ) {
            Text("Comienza hoy")
        }
    }
}

@Composable
private fun MenuMobile() {
    Div(attrs = {
        classes("col-12")
    }) {
        Div(attrs = {
            classes("mobile_menu", "d-block", "d-lg-none")
        })
    }
}