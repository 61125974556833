package index

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Img

@Composable
fun PreLoader() {
    Div(attrs = {
        id("preloader-active")
    }) {
        Div(attrs = {
            classes("preloader", "d-flex", "align-items-center", "justify-content-center")
        }) {
            Div(attrs = {
                classes("preloader-inner", "position-relative")
            }) {
                Div(attrs = {
                    classes("preloader-circle")
                })
                Div(attrs = {
                    classes("preloader-img", "pere-text")
                }) {
                    Img(src = "assets/img/logo/loder.png")
                }
            }
        }
    }
}