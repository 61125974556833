package index

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.dom.*

@Composable
fun Membership() {
    Section(attrs = {
        classes("wantToWork-area", "w-padding", "section-bg")
        attr("data-background", "assets/img/gallery/section_bg02.png")
        id("contact")
    }) {
        Div(attrs = {
            classes("container")
        }) {
            Div(attrs = {
                classes("row", "allign-items-center", "justify-content-between")
            }) {
                Div(attrs = {
                    classes("col-xl-6", "col-lg-7", "col-md-8", "col-sm-10")
                }) {
                    Div(attrs = {
                        classes("wantToWork-caption")
                    }) {
                        H2 {
                            Text("Conviertete en un miembro ahora")
                        }
                    }
                }
                Div(attrs = {
                    classes("col-xl-2", "col-lg-2", "col-md-3")
                }) {
                    A(href = "https://wa.me/573193769897", attrs = {
                        classes("btn", "wantToWork-btn", "f-right")
                    }) {
                        Text("Inscripciones abiertas!")
                    }
                }
            }
        }
    }
}