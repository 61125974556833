package index

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.dom.*

@Composable
fun About() {
    Section(attrs = {
        classes("about-area", "section-padding30")
        id("about")
    }) {
        Div(attrs = {
            classes("container")
        }) {
            Div(attrs = {
                classes("row", "align-items-center")
            }) {
                Div(attrs = {
                    classes("col-lg-6", "col-md-12")
                }) {
                    Div(attrs = {
                        classes("about-img")
                    }) {
                        Img("assets/img/gallery/about.png")
                    }
                }
                Div(attrs = {
                    classes("col-lg-6", "col-md-12")
                }) {
                    Div(attrs = {
                        classes("about-caption")
                    }) {
                        Div(attrs = {
                            classes("section-tittle", "section-tittle3", "mb-35")
                        }) {
                            Span { Text("Conocenos") }
                            H2 { Text("Sensei Cesar A. Peña Ch.") }
                        }
                        P(attrs = {
                            classes("pera-top")
                        }) {
                            Text("Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.")
                        }
                        P(attrs = {
                            classes("mb-65", "pera-bottom")
                        }) {
                            Text("Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed sit amet vulputate risus. Phasellus non lorem lacus. Phasellus malesuada egestas magna vel venenatis.")
                        }
                        A(href = "https://wa.me/573193769897", attrs = {
                            classes("btn", "hero-btn")
                        }) {
                            Text("Entrena con nosotros")
                        }
                    }
                }
            }
        }
    }
}