package index

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.css.Color
import org.jetbrains.compose.web.css.color
import org.jetbrains.compose.web.css.fontSize
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.*

@Composable
fun Services() {
    Section(attrs = {
        classes("services-area", "pt-100", "pb-150", "section-bg")
        attr("data-background", "assets/img/gallery/section_bg01.png")
        id("services")
    }) {
        Section(attrs = {
            classes("wantToWork-area", "w-padding")
        }) {
            Div(attrs = {
                classes("container")
            }) {
                Div(attrs = {
                    classes("row", "align-items-end", "justify-content-between")
                }) {
                    Div(attrs = {
                        classes("col")
                    }) {
                        Div(attrs = {
                            classes("section-tittle", "section-tittle2", "white")
                        }) {
                            H2 { Text("\"En el Karate no existe el primer ataque\"") }
                            Span(attrs = {
                                style {
                                    fontSize(30.px)
                                    color(Color.white)
                                }
                            }) { Text("Gishin Funakoshi") }
                        }
                    }
                }
            }
        }
        Div(attrs = {
            classes("container")
        }) {
            Div(attrs = {
                classes("row")
            }) {
                Service(
                    "assets/img/icons/belt.png",
                    "Principiantes",
                    "Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur."
                )
                Service(
                    "assets/img/icons/karategui.png",
                    "Intermedios",
                    "Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur."
                )
                Service(
                    "assets/img/icons/karate.png",
                    "Avanzados",
                    "Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur."
                )
            }
        }
    }
}

@Composable
private fun Service(
    icon: String,
    title: String,
    description: String
) {
    Div(attrs = {
        classes("col-lg-4", "col-md-4", "col-sm-6")
    }) {
        Div(attrs = {
            classes("single-cat", "text-center", "mb-50")
        }) {
            Div(attrs ={
                classes("cat-icon")
            }) {
                Img(
                    src = icon,
                    attrs ={
                        classes("karate-icon")
                    }
                )
            }
            Div(attrs = {
                classes("cat-cap")
            }) {
                H5 {
                    A(href = "#") {
                        Text(title)
                    }
                }
                P { Text(description) }
            }
            Div {
                A(href = "#") {
                    Text("Descubre mas")
                    I(attrs = {
                        classes("ti-arrow-right")
                    })
                }
            }
        }
    }
}