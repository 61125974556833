package index

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.css.Color
import org.jetbrains.compose.web.css.background
import org.jetbrains.compose.web.css.backgroundImage
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.H3
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Text
import kotlin.random.Random

@Composable
fun Gallery() {
    Div(attrs = {
        classes("gallery-area")
    }) {
        Div(attrs = {
            classes("container-fluid", "p-0", "fix")
        }) {
            Div(attrs = {
                classes("row")
            }) {
                BigContent()
                FourContent()
            }
        }
    }
}

@Composable
private fun BigContent() {
    Div(attrs = {
        classes("col-lg-6")
        id("gallery")
    }) {
        Div(attrs = {
            classes("box", "snake", "mb-30")
        }) {
            Div(attrs = {
                classes("gallery-img", "big-img")
                style { backgroundImage("url(../assets/img/gallery/gl1.jpg)") }
            })
            //OverlayGallery()
        }
    }
}

@Composable
private fun FourContent() {

    Div(attrs = {
        classes("col-lg-6")
    }) {
        Div(attrs = {
            classes("row")
        }) {
            SmallContent(3)
            SmallContent(4)
            SmallContent(5)
            SmallContent(6)
        }
    }
}

@Composable
private fun SmallContent(item: Int) {
    Div(attrs = {
        classes("col-lg-6", "col-md-6", "col-sm-6")
    }) {
        Div(attrs = {
            classes("box", "snake", "mb-30")
        }) {
            Div(attrs = {
                classes("gallery-img", "small-img")
                style { backgroundImage("url(../assets/img/gallery/gl$item.jpg)") }
            })
            //OverlayGallery()
        }
    }
}

@Composable
private fun OverlayGallery(title: String = "", subtitle: String = "") {
    Div(attrs = {
        classes("overlay")
    }) {
        Div(attrs = {
            classes("overlay-content")
        }) {
            H3 { Text(title) }
            P { Text(subtitle) }
        }
    }
}