package index

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.dom.*

@Composable
fun Experiences() {
    Section(attrs = {
        classes("wantToWork-area", "w-padding")
    }) {
        Div(attrs = {
            classes("container")
        }) {
            Div(attrs = {
                classes("row", "align-items-end", "justify-content-between")
            }) {
                Div(attrs = {
                    classes("col-lg-7", "col-md-9", "col-sm-9")
                }) {
                    Div(attrs = {
                        classes("section-tittle")
                    }) {
                        Span {
                            Text("Nuestro trabajo")
                        }
                        H2 {
                            Text("Karate-Do más que un deporte, un estilo de vida.")
                        }
                    }
                }
            }
        }
    }
}