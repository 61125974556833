import index.*
import org.jetbrains.compose.web.renderComposable

fun main() {
    renderComposable(rootElementId = "root") {
        PreLoader()

        HeaderNav()

        MainContent()

        Social()

        Services()

        Experiences()

        Gallery()

        Schedule()

        Membership()

        Footer()
    }
}

