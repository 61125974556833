package index

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.attributes.ATarget
import org.jetbrains.compose.web.attributes.target
import org.jetbrains.compose.web.dom.*

@Composable
fun Social() {
    Div(attrs = {
        classes("side-social-link")
    }) {
        Ul {
            SocialItem("bi-whatsapp", "https://wa.me/573193769897")
            SocialItem("bi-facebook", "https://www.facebook.com/people/Seishin-Dojo-Karate-Do-Shotokan/100063805920336/")
            SocialItem("bi-instagram", "https://www.instagram.com/dseishin/?hl=es")
        }
    }
}

@Composable
private fun SocialItem(
    icon: String,
    link: String
) {
    Li {
        A(
            href = link,
            attrs = {
                target(ATarget.Blank)
            }
        ) {
            I(attrs = {
                classes(icon)
            })
        }
    }
}
