package index

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.attributes.href
import org.jetbrains.compose.web.dom.*

@Composable
fun Schedule() {
    Section(attrs = {
        classes("date-tabs", "section-padding30")
        id("schedule")
    }) {
        Div(attrs = {
            classes("container")
        }) {
            Div(attrs = {
                classes("row", "justify-content-center")
            }) {
                Div(attrs = {
                    classes("col-lg-6", "col-md-8")
                }) {
                    Div(attrs = {
                        classes("section-tittle", "text-center", "mb-100")
                    }) {
                        Span { Text("Nuestras clases") }
                        H2 { Text("Conoce los horarios y edades de nuestras clases") }
                    }
                }
            }
            Div(attrs = {
                classes("row", "justify-content-center", "mb-10")
            }) {
                Div(attrs = {
                    classes("col-lg-8")
                }) {
                    Div(attrs = {
                        classes("properties__button")
                    }) {
                        Nav {
                            Div(attrs = {
                                classes("nav", "nav-tabs", "justify-content-center")
                                id("nav-tab")
                                attr("role", "tablist")
                            }) {
                                A(attrs = {
                                    classes("nav-item", "nav-link", "active")
                                    attr("data-toggle", "tab")
                                    attr("role", "tab")
                                    attr("aria-controls", "nav-monday")
                                    attr("aria-selected", "true")
                                    id("nav-monday-tab")
                                    href("#nav-monday")
                                }) {
                                    Text("Lunes")
                                }
                                A(attrs = {
                                    classes("nav-item", "nav-link")
                                    attr("data-toggle", "tab")
                                    attr("role", "tab")
                                    attr("aria-controls", "nav-wednesday")
                                    attr("aria-selected", "true")
                                    id("nav-wednesday-tab")
                                    href("#nav-wednesday")
                                }) {
                                    Text("Miercoles")
                                }
                                A(attrs = {
                                    classes("nav-item", "nav-link")
                                    attr("data-toggle", "tab")
                                    attr("role", "tab")
                                    attr("aria-controls", "nav-friday")
                                    attr("aria-selected", "true")
                                    id("nav-friday-tab")
                                    href("#nav-friday")
                                }) {
                                    Text("Viernes")
                                }
                                A(attrs = {
                                    classes("nav-item", "nav-link")
                                    attr("data-toggle", "tab")
                                    attr("role", "tab")
                                    attr("aria-controls", "nav-saturday")
                                    attr("aria-selected", "true")
                                    id("nav-saturday-tab")
                                    href("#nav-saturday")
                                }) {
                                    Text("Sabado")
                                }
                            }
                        }
                    }
                }
            }
            Div(attrs = {
                classes("row", "justify-content-center")
            }) {
                Div(attrs = {
                    classes("col-lg-10", "justify-content-center")
                }) {
                    Div(attrs = {
                        classes("tab-content", "justify-content-center")
                        id("nav-tabContent")
                    }) {
                        days("nav-monday", "nav-monday-tab", true)
                        days("nav-wednesday", "nav-wednesday-tab")
                        days("nav-friday", "nav-friday-tab")
                        days("nav-saturday", "nav-saturday-tab")
                    }
                }
            }
        }
    }
}

@Composable
private fun days(
    id: String,
    label: String,
    isActive: Boolean = false
) {
    Div(attrs = {
        if (isActive) {
            classes("tab-pane", "fade", "show", "active")
        } else {
            classes("tab-pane", "fade")
        }
        attr("role", "tabpanel")
        attr("aria-labelledby", label)
        id(id)
    }) {
        Div(attrs = {
            classes("row")
        }) {
            Div(attrs = {
                classes("col-12")
            }) {
                Div(attrs = {
                    classes("tab-wrapper")
                }) {
                    KidClass(id == "nav-saturday")
                    AdultClass(id == "nav-saturday")
                }
            }
        }
    }
}

@Composable
private fun KidClass(isSaturday: Boolean = false) {
    val time = if(isSaturday) "08:30 - 10:00" else "17:30 - 18:30"
    Div(attrs = {
        classes("single-box")
    }) {
        Div(attrs = {
            classes("single-caption", "text-center")
        }) {
            Div(attrs = {
                classes("caption")
            }) {
                Span { Text(time) }
                H3 { Text("Niños") }
                P {
                    Span { Text("Sensei: ") }
                    Text("Cesar Peña")
                }
            }
        }
    }
}

@Composable
private fun AdultClass(isSaturday: Boolean = false) {
    val time = if(isSaturday) "10:00 - 11:30" else "19:00 - 20:30"
    Div(attrs = {
        classes("single-box")
    }) {
        Div(attrs = {
            classes("single-caption", "text-center")
        }) {
            Div(attrs = {
                classes("caption")
            }) {
                Span { Text(time) }
                H3 { Text("Adultos") }
                P {
                    Span { Text("Sensei: ") }
                    Text("Cesar Peña")
                }
            }
        }
    }
}