package index

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.attributes.ATarget
import org.jetbrains.compose.web.attributes.target
import org.jetbrains.compose.web.css.Color
import org.jetbrains.compose.web.css.background
import org.jetbrains.compose.web.dom.A
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Text
import org.jetbrains.compose.web.dom.Footer as HTMLFooter

@Composable
fun Footer() {
    HTMLFooter {
        Div(attrs = {
            classes("footer-area", "section-bg")
            style {
                background(Color.black.toString())
            }
        }) {
            Div(attrs = {
                classes("container")
            }) {
                Div(attrs = {
                    classes("footer-bottom")
                }) {
                    Div(attrs = {
                        classes("row", "d-flex", "align-items-center")
                    }) {
                        Div(attrs = {
                            classes("col-lg-12")
                        }) {
                            Div(attrs = {
                                classes("footer-copy-right", "text-center")
                            }) {
                                P {
                                    Text("Copyright ⒸSeishin Dojo JKA 2022, Se reservan los derechos. Powered by: ")
                                    A(
                                        href = "https://studioMatna.com",
                                        attrs = {
                                            target(ATarget.Blank)
                                        }
                                    ) { Text("studioMatna") }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}