package index

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.*

@Composable
fun Slider() {
    Div(attrs = {
        classes("slider-area", "position-relative")
        style {
            backgroundAttachment("unset")
        }
    }) {
        Div(attrs = {
            classes("slider-active")
        }) {
            SingleSlider(description = "Karate-Do: Camino de la mano vacía.")
            SingleSlider(description = "Buscar la perfección del caracter.")
            SingleSlider(description = "Ser correcto, leal y puntual.")
            SingleSlider(description = "Siempre superarse.")
            SingleSlider(description = "Respetar a los demás.")
            SingleSlider(description = "Abstenerse de comportamientos violentos.")
        }
    }
}

@Composable
private fun SingleSlider(
    title: String = "Seishin JKA Dojo",
    description: String
) {
    Div(attrs = {
        classes("single-slider", "slider-height", "d-flex", "align-items-center")
    }) {
        Div(attrs = {
            classes("container")
        }) {
            Div(attrs = {
                classes("row")
            }) {
                Div(attrs = {
                    classes("col-xl-7", "col-lg-9", "col-md-8", "col-sm-9")
                }) {
                    Div(attrs = {
                        classes("hero__caption")
                    }) {
                        Span(attrs = {
                            style {
                                fontSize(45.px)
                                color(Color.white)
                            }
                            //attr("data-animation", "fadeInLeft")
                            //attr("data-delay", "0.1s")
                        }) {
                            Text(title)
                        }
                        H1(attrs = {
                            attr("data-animation", "fadeInLeft")
                            attr("data-delay", "0.6s")
                        }) {
                            Text(description)
                        }
                        //WhatsAppButton()
                    }
                }
            }
        }
    }
}

@Composable
private fun WhatsAppButton() {
    A(href = "https://wa.me/573193769897", attrs = {
        classes("btn", "hero-btn")
        attr("data-animation", "fadeInLeft")
        attr("data-delay", "0.8s")
    }) {
        Text("Se un miembro")
    }
}