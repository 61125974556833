package index

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.dom.*

@Composable
fun MainContent() {
    Main {
        Slider()
        About()
    }
}


